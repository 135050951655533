import { MDBCard, MDBRipple } from "mdb-react-ui-kit";
import { FC, useState } from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import { getDestinationsSize } from "@/utils/common";
import styles from "../../../../styles/home/Home.NewCardTour.module.scss";
import HeadOverlay from "./HeadOverlay";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";
import { slugify } from "../../../../utils/searchUtils";
import { ToursEntity } from "../../../../models/home";

const NewCardTour: FC<{
    content: ToursEntity;
    isSmall?: boolean;
    className?: string;
    classNameBody?: string;
}> = ({ content, isSmall, className = "", classNameBody = "flex-fill" }) => {
    const [error, setError] = useState(false);
    const router = useRouter();

    const handleClick = () => {
        router.push({
            pathname: "/detail/[slug]",
            query: {
                slug: slugify(content.title ?? "Title"),
            },
        });
    };

    /* eslint-disable no-underscore-dangle */
    return (
        <MDBCard
            className={`${styles.cardBody} ${className} d-flex flex-column justify-content-between align-content-between h-100"`}
        >
            <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className={`${styles.ripple} bg-image hover-overlay position-relative flex-fill`}
            >
                <div className="position-relative h-100 flex-fill">
                    <Image
                        className="object-cover h-100"
                        src={
                            error
                                ? "/images/tours/across-italy-reduced.jpg"
                                : content.image
                        }
                        alt="avatar-image"
                        fill
                        onError={() => {
                            setError(true);
                        }}
                    />
                </div>
                {/* {content.tag && ( */}
                {/*    <div */}
                {/*        className={`${isSmall ? styles.tagSmall : styles.tag}`} */}
                {/*    > */}
                {/*        {content.tag} */}
                {/*    </div> */}
                {/* )} */}
                <HeadOverlay
                    id={content._id}
                    rating={content.rating}
                    isSmall={!isSmall}
                    onClick={handleClick}
                />
            </MDBRipple>
            <CardBody
                title={content.title ?? "Title"}
                duration={content.duration}
                nightDuration={content.nightDuration}
                destinationCount={getDestinationsSize(content?.destinations)}
                isSmall={!!isSmall}
                className={classNameBody}
                handleClick={handleClick}
            />
            <hr className="mx-3" />
            <CardFooter
                price={+content.price}
                rating={content.rating}
                reviewsCount={content.reviews}
                isSmall={!!isSmall}
                className="mt-auto"
                handleClick={handleClick}
            />
        </MDBCard>
    );
    /* eslint-enable no-underscore-dangle */
};

NewCardTour.defaultProps = {
    isSmall: false,
    className: "",
};

export default NewCardTour;
